import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';
import 'firebase/compat/app';

const firebaseConfig = {
    apiKey: "AIzaSyCGckxbMrdbfNSef6v-cg90scSe3HedkGI",
    authDomain: "corgi-52fc6.firebaseapp.com",
    databaseURL: "https://corgi-52fc6.firebaseio.com",
    projectId: "corgi-52fc6",
    storageBucket: "corgi-52fc6.appspot.com",
    messagingSenderId: "1081613168898",
    appId: "1:1081613168898:web:d8d3da706a7d2330e1d21b",
    measurementId: "G-4QWDY6JK4T"
};

function getAuthenticator() {
    const firebaseApp = initializeApp(firebaseConfig);
    const analytics = getAnalytics(firebaseApp);
    return getAuth(firebaseApp);
}

const authenticator = getAuthenticator();

export { authenticator };
