import { authenticator } from '../authentication/firebaseAuthenticator';
import { getRedirectResult, signInWithPopup, signInWithRedirect, GoogleAuthProvider } from 'firebase/auth';

const DEFAULT_REDIRECTION_PAGE = '/blazer';

function findRedirectionPage() {
    const extractUrlToRedirectTo = /(^| )redirect_to=([^;]+)/;
    const match = document.cookie.match(extractUrlToRedirectTo);
    return match ? decodeURIComponent(match[2]) : DEFAULT_REDIRECTION_PAGE;
}

async function setIdToken(user) {
    try {
        const idToken = await user.getIdToken();
        const maxAgeInSeconds = 60 * 60 * 24 * 30;
        document.cookie = `id_token=${idToken};max-age=${maxAgeInSeconds};path=/`;
        const redirectTo = findRedirectionPage();
        window.location.replace(redirectTo);
    } catch (error) {
        console.log('ID Token Error', error);
        alert('ID Token Error');
    }
}

async function init() {
    const provider = new GoogleAuthProvider();

    try {
        let result = await getRedirectResult(authenticator);
        if (result) {
            await setIdToken(result.user);
            return;
        }

        result = await signInWithPopup(authenticator, provider);
        if (result) {
            await setIdToken(result.user);
            return;
        }

        signInWithRedirect(authenticator, provider);
    } catch (error) {
        console.log('Redirect result Error', error);
        alert('Redirect result Error');
    }
}

init();
